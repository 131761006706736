import React, { Component } from "react";
import Menu from "./Menu.js"
// import './Header.css';
class Footer extends Component {


  render() {

    return (

      <div className="flex justify-center px-6 py-12 text-center text-gray-100 bg-gray-900">
        
        <span><a href='#home'>Consected</a> &copy; 2021</span>

      </div>

    )
  }
}
export default Footer;
