import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import Header from "./Header.js"

class NotFound extends Component {



  render() {

    return (
      <div class="w-full bg-white">

        <header className="relative z-10 flex items-center justify-between max-w-6xl px-6 py-8 mx-auto">
          <a href="/" className="text-xl font-bold tracking-wider font-logo">
            <img src="/logo192.png" />
          </a>
          <span className="text-gray-500">
          </span>
        </header>
        <div class="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
          <h1 class=" text-xl md:text-4xl pb-4">Sorry - that page was not found</h1>
          <p class="leading-loose text-gray-dark">
            <a href="/">Go to the home page</a>
          </p>
        </div>
      </div>
    )
  }
}

export default NotFound