import React, { Component } from "react";
import Menu from "./Menu.js"
// import './Header.css';
class Header extends Component {


  render() {

    return (

      <header className="relative z-10 flex items-center justify-between max-w-6xl px-6 py-8 mx-auto">
        <a href="/" className="text-xl font-bold tracking-wider font-logo">
          <img src="/logo192.png" />
        </a>
        <span className="text-gray-500">
          <Menu></Menu>
        </span>
      </header>

    )
  }
}
export default Header;
