import './App.css';
import Home from './Home'
import Software from './Software'
import Marketing from './Marketing'
import About from './About'
import AboutContent from './AboutContent.md'
import Contact from './Contact'
import NotFound from './NotFound'
import { Router } from "@reach/router";

function App() {
  return (
    <div className="App">
      <Router>
        <Home path='/' />
        <NotFound default />
      </Router>
      

    </div>
  );
}

export default App;
