import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import Header from "./Header.js";
import ReactMarkdown from "react-markdown";

import AppMarkdown from "./SoftwareContent.md";
class Software extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  showHeader() {
    if (this.props.noHeader) return "";
    else return <Header></Header>;
  }

  render() {
    const { markdown } = this.state;

    return (
      <div>
        {this.showHeader()}
        <div className="flex flex-col-reverse flex-wrap w-full lg:flex-row lg:-mt-24 lg:h-screen lg:pt-24">
          <div className="flex items-center justify-center w-full lg:h-full lg:w-1/2">
            <div className="max-w-xl px-12 text-justify lg:px-32">
              <div className="markdown-content">
                <ReactMarkdown source={markdown} />
              </div>
              <div class="mt-6">
                <a href="#restructure" className="px-8 py-4 border border-black hover:bg-silver hover:text-gray">
                  Platform
                </a>
              </div>
            </div>
          </div>

          <div className="relative w-full lg:h-full lg:w-1/2">
            <div
              className="w-full min-image-h mb-8 bg-center bg-no-repeat bg-cover lg:h-full lg:mb-0"
              style={{ backgroundImage: "url('/tech.jpg')" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Software;
