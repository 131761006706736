import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import Menu from "./Menu.js"
import About from './About.js'
import Software from './Software.js'
import Marketing from './Marketing.js'
import ReStructure from './ReStructure.js'
import Contact from './Contact.js'
import Footer from "./Footer.js"

import './Home.css'


class Home extends Component {


  render() {

    return (
    <div id="home">
      <div className="relative w-full h-screen text-white">
        <img src="/chile.jpg" className="absolute top-0 left-0 object-cover w-full h-full" />

        <header className="relative z-10 flex items-center justify-between px-10 py-8 mx-auto max-w-8xl">
          
          <a href="#" className="text-2xl font-normal tracking-wider font-logo logo">
            <img class="logo" src="/logo-clear-white-128.png" />
          </a>
          
          <Menu></Menu>

        </header>

        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full max-w-5xl px-12 text-center">
          <div className="mt-16">
            <h1 className="mb-16 text-3xl leading-tight md:text-6xl main-text">
              Do your business processes feel like they belong in a museum?
              <div className="mb-12 md:block" />
              Time for a refresh?
            </h1>
            <a href="#about" className="px-8 py-4 bg-gray-800 border border-white hover:bg-white hover:text-black" style={{backgroundColor: '#167ba7'}}>Find out how</a>
          </div>
        </div>

      </div>


      <div className="px-6 py-12 content-page" id="about">
        <About noHeader="true"/>
      </div>
      
      <div className="px-6 py-12 content-page" id="software">
        <Software noHeader="true"/>
      </div>

      <div className="px-6 py-12 content-page" id="restructure">
        <ReStructure noHeader="true"/>
      </div>
      
      <div className="px-6 py-12 content-page" id="marketing">
        <Marketing noHeader="true"/>
      </div>
      

      

      <div className="px-6 py-12 content-page" id="contact">
        <Contact noHeader="true"/>
      </div>



      <Footer></Footer>
    </div>

    );
    
  }
}
export default Home;
