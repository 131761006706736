import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import Header from "./Header.js"

class Contact extends Component {

  showHeader() {
    if (this.props.noHeader)
      return '';
    else
      return <Header></Header>;
  }


  render() {

    return (
      <div class="w-full bg-white">

        
        {this.showHeader()}
        <div class="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
          <h1 class=" text-xl md:text-4xl pb-4">Contact Us</h1>
          <p class="leading-loose text-gray-dark">
            Have questions about what we offer or how we can help?
          </p>
        </div>

        <div class="container max-w-4xl mx-auto pb-10 px-12 md:px-0">
  
          <div className="flex items-center justify-center w-full ">
          
            <div className="max-w-xl px-12 lg:px-32">
              <div className="markdown-content">
                
                <p class="text-black font-bold mb-1">
                  Consected LLC, Waltham, MA USA
                </p>
                <p class="text-black font-bold mb-1">
                  Consected, London, UK
                </p>
                <p>Owner: Phil Ayres</p>

                <p><a href="mailto:info@consected.com?subject=Web%20inquiry">info@consected.com</a></p>
              </div>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default Contact