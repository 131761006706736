import React, { Component } from "react";

// import './Menu.css';

class Menu extends Component {

  render() {

    return (

          <ul className="hidden text-sm uppercase md:flex">
            <li>
              <a href="#home">Home</a>
            </li>
            <li className="ml-6">
              <a href="#about">About Us</a>
            </li>
            <li className="ml-6">
              <a href="#software">Software</a>
            </li>
            <li className="ml-6">
              <a href="#marketing">Marketing</a>
            </li>
            <li className="ml-6">
              <a href="#contact">Contact</a>
            </li>
          </ul>

    )
  }
}
export default Menu;
